
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { Emitter, computed, defineComponent, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { iconX } from '@/components/icon'
import { isIpadPro } from '@/utils'

export default defineComponent({
    components: {
        'icon-x': iconX
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const store = useStore()
        const message = ref('')
        const oldpassword = ref('')
        const newpassword = ref('')
        const confirmpassword = ref('')

        const close = () => {
            const _event: MessageBoxEvent = {
                type: MessageBoxActionType.close,
                name: 'edit-password'
            }
            emitter.emit(EventBuss.DIALOG, _event)
        }

        const confirm = () => {
            message.value = 'lllsdfde'
            if (oldpassword.value.trim().length === 0) {
                message.value = t('settings.oldpassworderror')
                return
            }

            if (newpassword.value.trim().length === 0) {
                message.value = t('settings.newpassworderror')
                return
            }

            if (newpassword.value.trim() !== confirmpassword.value.trim()) {
                message.value = t('settings.passwordnotmatch')
                return
            }

            message.value = ''

            // call api

            store.commit(AppMutation.SHOW_LOADING)

            const _params = {
                ...OtherApiParams,
                opwd: oldpassword.value,
                npwd: newpassword.value,
                username: user.value,
                act: ACT.CHANGE_PASS
            }

            store
                .dispatch(AppActions.FETCH_DATA, _params)
                .then((_data: string) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (_data.includes('err=')) {
                        message.value = t('settings.changepassworderror')
                    } else {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            t('prompt.modifyok')
                        )
                        close()
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const user = computed(() => store.getters['user'])
        const isIpadProDevice = computed((): boolean => isIpadPro())

        return {
            isIpadProDevice,
            message,
            oldpassword,
            newpassword,
            confirmpassword,
            close,
            confirm,
            t
        }
    }
})
