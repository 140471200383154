import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3de16786"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "language-content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["langauge-root", { 'is-not-ipad': !_ctx.isIpadProDevice }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('settings.language')), 1),
        _createElementVNode("div", {
          class: "close",
          onClickPassive: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, [
          _createVNode(_component_icon_x)
        ], 32)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langArr, (i) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass([{ active: i.code === _ctx.currentLang }, i.code]),
              key: `flag_${i.code}`,
              onClick: ($event: any) => (_ctx.selectLanguage(i.code))
            }, _toDisplayString(i.name), 11, _hoisted_5))
          }), 128))
        ])
      ])
    ])
  ], 2))
}