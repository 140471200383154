import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e8e634a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-custom-chip-content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "field" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["edit-custom-chip-root", { 'is-not-ipad': !_ctx.isIpadProDevice }]),
    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('game.customchip')), 1),
        _createVNode(_component_icon_x, {
          class: "close",
          onClickPassive: _ctx.close
        }, null, 8, ["onClickPassive"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          action: "",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('common.chipamount')), 1),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chipValue) = $event))
            }, null, 512), [
              [_vModelText, _ctx.chipValue]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.message), 1)
        ], 32),
        _createElementVNode("div", {
          class: "confirm",
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)), ["prevent"]))
        }, _toDisplayString(_ctx.t('common.confirm')), 1)
      ])
    ])
  ], 2))
}