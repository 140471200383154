import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce9a295c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bet-history-content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "search-container" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "arrow" }
const _hoisted_9 = { class: "input" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "arrow" }
const _hoisted_12 = {
  key: 0,
  class: "content"
}
const _hoisted_13 = {
  key: 0,
  class: "header"
}
const _hoisted_14 = { class: "_94" }
const _hoisted_15 = { class: "_63" }
const _hoisted_16 = { class: "_83" }
const _hoisted_17 = { class: "_83" }
const _hoisted_18 = { class: "_83" }
const _hoisted_19 = {
  key: 0,
  class: "_83"
}
const _hoisted_20 = { class: "_83" }
const _hoisted_21 = { class: "_94" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  colspan: "8",
  class: "norecord"
}
const _hoisted_24 = { key: 0 }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = {
  key: 1,
  class: "header"
}
const _hoisted_27 = { class: "_69" }
const _hoisted_28 = { class: "_68" }
const _hoisted_29 = { class: "_204" }
const _hoisted_30 = { class: "_228" }
const _hoisted_31 = { class: "_68" }
const _hoisted_32 = { class: "_34" }
const _hoisted_33 = { class: "_135" }
const _hoisted_34 = {
  key: 1,
  class: "content"
}
const _hoisted_35 = {
  key: 0,
  class: "m-content"
}
const _hoisted_36 = { key: 0 }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = {
  key: 1,
  class: "m-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_x = _resolveComponent("icon-x")!
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bet-history-root", { 'is-not-ipad': !_ctx.isIpadProDevice }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('menu.accounthistory')), 1),
        _createVNode(_component_icon_x, {
          class: "close",
          onClickPassive: _ctx.close
        }, null, 8, ["onClickPassive"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_date_picker, {
            class: _normalizeClass(["callendar-picker", { off: _ctx.showDetails }]),
            "is-range": "",
            columns: 2,
            locale: _ctx.lang,
            modelValue: _ctx.dateValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateValue) = $event)),
            masks: _ctx.masks,
            "min-date": _ctx.minDate(),
            "model-config": _ctx.modelConfig
          }, {
            default: _withCtx(({ inputValue, inputEvents }) => [
              _createTextVNode(_toDisplayString(_ctx.t('bethistory.startdate')) + " ", 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("input", _mergeProps({
                  type: "text",
                  readonly: "",
                  value: inputValue.start
                }, _toHandlers(inputEvents.start)), null, 16, _hoisted_7),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_arrow_down)
                ])
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t('bethistory.enddate')) + " ", 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("input", _mergeProps({
                  type: "text",
                  readonly: "",
                  value: inputValue.end
                }, _toHandlers(inputEvents.end)), null, 16, _hoisted_10),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_arrow_down)
                ])
              ])
            ]),
            _: 1
          }, 8, ["class", "locale", "modelValue", "masks", "min-date", "model-config"]),
          _createElementVNode("div", {
            class: "btn-search",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.viewBetHistory && _ctx.viewBetHistory(...args)))
          }, _toDisplayString(_ctx.t('bethistory.inquire')), 1)
        ]),
        (_ctx.showLandscape)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (!_ctx.showDetails)
                ? (_openBlock(), _createElementBlock("table", _hoisted_13, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.t('bethistory.date')), 1),
                        _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.t('bethistory.transactionno')), 1),
                        _createElementVNode("th", _hoisted_16, _toDisplayString(_ctx.t('bethistory.totalvote')), 1),
                        _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.t('bethistory.twin')), 1),
                        _createElementVNode("th", _hoisted_18, _toDisplayString(_ctx.t('bethistory.wascodebetting')), 1),
                        (_ctx.xmRate > 0)
                          ? (_openBlock(), _createElementBlock("th", _hoisted_19, _toDisplayString(_ctx.t('bethistory.codewashingfee')), 1))
                          : _createCommentVNode("", true),
                        _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.t('bethistory.summary')), 1),
                        _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.t('bethistory.details')), 1)
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_ctx.betLogs.length === 0)
                        ? (_openBlock(), _createElementBlock("tr", _hoisted_22, [
                            _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.t('bethistory.norecordfound')), 1)
                          ]))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.betLogs, (i, index) => {
                            return (_openBlock(), _createElementBlock("tr", {
                              key: `rh-pc-${index}`
                            }, [
                              _createElementVNode("td", null, _toDisplayString(i.mday), 1),
                              _createElementVNode("td", null, _toDisplayString(i.count), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(i.sumPointed)), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(i.sumOrderPoint)), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(i.sumxmOrderd)), 1),
                              (_ctx.xmRate > 0)
                                ? (_openBlock(), _createElementBlock("td", _hoisted_24, _toDisplayString(_ctx.roundFixed2(i.selfxmOrderd)), 1))
                                : _createCommentVNode("", true),
                              _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(
                                            (i.sumOrderPoint || 0) +
                                                (i.selfxmOrderd || 0)
                                        )), 1),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("span", {
                                    class: "btn",
                                    onClick: ($event: any) => (
                                                _ctx.OnClickLogsItem(i.mday || '')
                                            )
                                  }, _toDisplayString(_ctx.t('bethistory.details')), 9, _hoisted_25)
                                ])
                              ])
                            ]))
                          }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("table", _hoisted_26, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", _hoisted_27, _toDisplayString(_ctx.t('bethistory.gameno')), 1),
                        _createElementVNode("th", _hoisted_28, _toDisplayString(_ctx.t('bethistory.bettingtable')), 1),
                        _createElementVNode("th", _hoisted_29, _toDisplayString(_ctx.t('bethistory.gameresult')), 1),
                        _createElementVNode("th", _hoisted_30, _toDisplayString(_ctx.t('bethistory.typeofbet')), 1),
                        _createElementVNode("th", _hoisted_31, _toDisplayString(_ctx.t('bethistory.betamount')), 1),
                        _createElementVNode("th", _hoisted_32, _toDisplayString(_ctx.t('bethistory.winlose')), 1),
                        _createElementVNode("th", _hoisted_33, _toDisplayString(_ctx.t('bethistory.bettime')), 1)
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.betLogDetails, (i, index) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: `rhv-pc-${index}`
                        }, [
                          _createElementVNode("td", null, _toDisplayString(i.gameid), 1),
                          _createElementVNode("td", null, _toDisplayString(i.desk), 1),
                          _createElementVNode("td", null, _toDisplayString(i.jieguo), 1),
                          _createElementVNode("td", null, _toDisplayString(i.touzhu), 1),
                          _createElementVNode("td", null, _toDisplayString(i.pointed), 1),
                          _createElementVNode("td", null, _toDisplayString(i.orderpoint), 1),
                          _createElementVNode("td", null, _toDisplayString(i.betTime), 1)
                        ]))
                      }), 128))
                    ])
                  ]))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_34, [
              (!_ctx.showDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.betLogs, (i, index) => {
                      return (_openBlock(), _createElementBlock("table", {
                        key: `mh-mc-${index}`
                      }, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.date')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.mday), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.transactionno')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.count), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.totalvote')), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(i.sumPointed)), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.twin')), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(i.sumOrderPoint)), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.wascodebetting')), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(i.sumxmOrderd)), 1)
                        ]),
                        (_ctx.xmRate > 0)
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_36, [
                              _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.codewashingfee')), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(i.selfxmOrderd)), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.summary')), 1),
                          _createElementVNode("td", null, _toDisplayString(_ctx.roundFixed2(
                                            (i.sumOrderPoint || 0) +
                                                (i.selfxmOrderd || 0)
                                        )), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.details')), 1),
                          _createElementVNode("td", null, [
                            _createElementVNode("span", {
                              onClick: ($event: any) => (_ctx.OnClickLogsItem(i.mday || ''))
                            }, _toDisplayString(_ctx.t('bethistory.details')), 9, _hoisted_37)
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.betLogDetails, (i, index) => {
                      return (_openBlock(), _createElementBlock("table", {
                        key: `phv-mc-${index}`
                      }, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.gameno')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.gameid), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.bettingtable')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.desk), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.gameresult')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.jieguo), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.typeofbet')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.touzhu), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.betamount')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.pointed), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.winlose')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.orderpoint), 1)
                        ]),
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", null, _toDisplayString(_ctx.t('bethistory.bettime')), 1),
                          _createElementVNode("td", null, _toDisplayString(i.betTime), 1)
                        ])
                      ]))
                    }), 128))
                  ]))
            ]))
      ])
    ])
  ], 2))
}